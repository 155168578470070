import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import momentTimezone from "moment-timezone";

import { APIStatus } from "./APIStatus";

const initialState = {
  meteringDashboardStatus: APIStatus.idle,
  meterList: null,
  meterListError: null,
  meterInfoTableList: null,
  meterInfoTableListError: null,
  meterDetailsList: null,
  meterDetailsListError: null,
};

export const getEnergyFeed = createAsyncThunk(
  "getEnergyFeed",
  async (payload) => {
    const response = await axios.get(
      `/${payload.organization}/feeds/Energy/data-cumulative/${payload.startDate}/${payload.endDate}`
    );
    return response;
  }
);

export const getMeterInfoTable = createAsyncThunk(
  "getMeterInfoTable",
  async (payload) => {
    const response = await axios.get(
      `/${payload.organization}/feeds/Energy/data-cumulative/${payload.startDate}/${payload.endDate}`
    );
    return response;
  }
);

export const getFeedsCumulative = createAsyncThunk(
  "getFeedsCumulative",
  async (payload) => {
    let apiEndPoint;
    const startFromDate = moment(`${payload.startDate} 00:00:00`)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    const endToDate = moment(`${payload.endDate} 23:59:59`)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    if (payload.wise === "month" || payload.wise === "week") {
      apiEndPoint = `/${payload.organization}/feeds-cumulative/${payload.feedKey}/${payload.startDate}/${payload.endDate}/${payload.wise}`;
    } else {
      apiEndPoint = `/${payload.organization}/feeds/${payload.feedKey}/data-cumulative/${payload.frequency}?completed_at__range=${startFromDate},${endToDate}`;
    }
    const response = await axios.get(apiEndPoint);
    return response;
  }
);

const meteringDashboardSlice = createSlice({
  name: "meteringDashboardSlice",
  initialState,
  reducers: {
    reset(state, action) {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnergyFeed.pending, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loading;
      })
      .addCase(getEnergyFeed.fulfilled, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loaded;
        state.meterList = action.payload ? action.payload.data : null;
      })
      .addCase(getEnergyFeed.rejected, (state, action) => {
        state.meteringDashboardStatus = APIStatus.failed;
        state.meterListError = action.error;
      })
      .addCase(getMeterInfoTable.pending, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loading;
      })
      .addCase(getMeterInfoTable.fulfilled, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loaded;
        state.meterInfoTableList = action.payload ? action.payload.data : null;
      })
      .addCase(getMeterInfoTable.rejected, (state, action) => {
        state.meteringDashboardStatus = APIStatus.failed;
        state.meterInfoTableListError = action.error;
      })
      .addCase(getFeedsCumulative.pending, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loading;
      })
      .addCase(getFeedsCumulative.fulfilled, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loaded;
        state.meterDetailsList = action.payload ? action.payload.data : null;
      })
      .addCase(getFeedsCumulative.rejected, (state, action) => {
        state.meteringDashboardStatus = APIStatus.failed;
        state.meterDetailsListError = action.error;
      });
  },
});

export const { reset } = meteringDashboardSlice.actions;

export default meteringDashboardSlice.reducer;
